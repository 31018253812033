import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'

import { components } from '../slices'
import { BsChevronRight, BsWindowSidebar } from 'react-icons/bs'

const CaseTemplate = ({ data }) => {
  if (!data) return null
  const doc = data.prismicCase.data

  useEffect(() => {
    const headerImage = document.getElementById('case-headerimage')
    const headerImageOpacity = () => {
      if (window.scrollY >= window.innerHeight * 2) {
        headerImage.style.opacity = '0'
      } else {
        headerImage.style.opacity = '1'
      }
    }
    window.addEventListener('scroll', headerImageOpacity)

    return () => {
      window.removeEventListener('scroll', headerImageOpacity)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      function reveal() {
        var reveals = [...document.querySelectorAll('.revealblock')]

        reveals.forEach((e, i) => {
          setTimeout(function () {
            e.classList.add('active')
          }, i * 300)
        })
      }

      window.addEventListener(`load`, reveal())
      return () => {
        window.removeEventListener(`load`, reveal())
      }
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      function hideTitle() {
        var title = document.querySelector('.titleblock')
        if (window.scrollY >= window.innerHeight / 2) {
          title.classList.add('active')
        } else {
          title.classList.remove('active')
        }
      }
      window.addEventListener('scroll', hideTitle)
      return () => {
        window.removeEventListener('scroll', hideTitle)
      }
    }
  }, [])

  return (
    <Layout>
      <Seo title={doc.title.text} />
      <div className="case-container">
        <img
          className="case-headerimage"
          id="case-headerimage"
          src={doc.image.url}
        />
        <div className="case">
          <div className="revealblock revealblock-1"></div>
          <div className="revealblock revealblock-2"></div>
          <div className="revealblock revealblock-3"></div>
          <div className="titleblock">
            <h1>{doc.title.text}</h1>
          </div>
        </div>
        <div className="element">
          <div className="textblock">
            <div className="textblock-content">
              <h2>{doc.subtitle.text}</h2>
              <h4>{doc.text.text}</h4>
            </div>
          </div>
        </div>
        <div className="case-slice">
          <SliceZone slices={doc.body} components={components} />
        </div>
      </div>
      <div className="container"></div>
    </Layout>
  )
}

export const query = graphql`
  query CaseQuery($id: String) {
    prismicCase(id: { eq: $id }) {
      _previewable
      data {
        title {
          text
        }
        subtitle {
          text
        }
        text {
          text
        }
        image {
          url
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...CaseDataBodyText
          ...CaseDataBodyFullWidthImage
          ...CaseDataBodyThreeColumns
          ...CaseDataBodyCardsCarousel1
          ...CaseDataBodySectionTitle
          ...CaseDataBodyTwoCol
          ...CaseDataBodyBlankSpace
          ...CaseDataBodyCasesBlurbFullwidth
          ...CaseDataBodyCasesBlurbThreecol
        }
      }
    }
  }
`

export default withPrismicPreview(CaseTemplate)
